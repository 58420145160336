<template>
  <div>
    <Row :gutter="8" class="m-b-5">
      <i-col span="4">
        <Select size="small" v-model="query.type" placeholder="发布类型" clearable>
          <Option v-for="(orderType, index) in orderTypeList" :key="index" :value="orderType.type">{{ orderType.name }}
          </Option>
        </Select>
      </i-col>
      <i-col span="4">
        <DatePicker size="small" :show-week-numbers="true" type="daterange" v-model="orderSchdule"
          @on-change="scheduleChange" style="width: 100%" placement="top-start" placeholder="订单档期"></DatePicker>
      </i-col>
      <i-col span="8">
        <i-input size="small" v-model='query.keyword' placeholder="客户名称/投放品牌/编号/备注"></i-input>
      </i-col>
      <i-col span="8">
        <Button size="small" type="primary" icon="ios-search" @click="SearchOrders">搜索</Button>
      </i-col>
    </Row>

    <Table size="small" stripe :columns="columndata" :data="tableData"></Table>
    <div class="paging_style">
      <Page size="small" :total="total" :page-size="query.pageSize" :current="query.pageNumber" show-total show-elevator
        @on-change="changePage"></Page>
    </div>

    <purchase-resource-list ref="purchaseResourceList" />
  </div>
</template>

<script>
import purchaseResourceList from '@/components/order/common/PurchaseResourceList'

import { sysMixins } from '@/assets/mixins/sys'
import { formatOrderStatus } from '@/utils/tagStatus'
import { GetCurrentSchedule, GetDateStr } from '@/utils/dateFormat'

import { getOrderTypeList, getOwnerOrderPage } from '@/api/order/order'

export default {
  mixins: [sysMixins],
  components: { purchaseResourceList },
  data () {
    return {
      orderTypeList: [], // 订单发布类型
      orderSchdule: [],

      query: {
        pageNumber: 1,
        pageSize: 15,
        endDate: '',
        keyword: '',
        startDate: '',
        status: '',
        type: '',
        showProductQuantity: true
      },

      total: 0,
      tableData: [],
      columndata: [
        { title: '编号', key: 'code', width: 145 },
        { title: '客户名称', key: 'advertiserName' },
        { title: '投放品牌', key: 'brandName', width: 120 },
        { title: '发布类型', key: 'typeName', width: 80 },
        { title: '备注', key: 'remark' },
        {
          title: '投放档期',
          render: (h, data) => {
            const spans = []
            data.row.purchasedScheduleList.forEach(item => {
              spans.push(h('span', GetCurrentSchedule(item.startDate, item.endDate)))
              spans.push(h('br'))
            })
            return h('div', spans)
          }
        },
        {
          title: '采购资源统计',
          width: 120,
          render: (h, params) => {
            const spans = []
            params.row.orderProductQuantityBeanList.forEach(resource => {
              spans.push(h(
                'span',
                resource.productName
              ))
              spans.push(h(
                'span',
                {
                  attrs: {
                    class: 'text-orange text-12'
                  }
                },
                resource.quantity
              ))
              spans.push(h(
                'span',
                resource.unitName
              ))
              spans.push(h('br'))
            })
            return h('div', {
              attrs: {
                class: 'text-href'
              },
              on: {
                click: () => {
                  this.$refs.purchaseResourceList.showProductSkuList(params.row.id)
                }
              }
            }, spans)
          }
        },
        {
          title: '状态',
          align: 'center',
          width: 100,
          render: (h, params) => {
            return formatOrderStatus(h, params.row.status, params.row.statusName)
          }
        },
        {
          title: '操作',
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h('a', {
                on: {
                  click: () => {
                    this.handleShowDetail(params.row)
                  }
                }
              }, '详情')
            ])
          }
        }
      ]
    }
  },
  created () {
    getOrderTypeList().then(res => {
      this.orderTypeList = res
    })
    this.initOrderList()

    // 设置头部路由标签
    this.setLinkTagArray({ key: 'ownerOrderIndex', value: '订单列表' })
    this.setLinkRouterMaps(['ownerOrderIndex', () => {
      this.setShowLeftBlock(false)
      this.setShowMap(false)
      this.setShowRightBlock(false)
      this.setLeftComponent('')
      this.setRightComponent('')
      this.setBottomComponent('IndexTable')
    }])
    this.setActivedTagName('ownerOrderIndex')
  },
  methods: {
    initOrderList () {
      getOwnerOrderPage(this.query).then(response => {
        this.total = response.totalRow
        this.tableData = response.list
        this.query.pageNumber = response.pageNumber
      })
    },
    scheduleChange () {
      const shcedule = this.orderSchdule
      if (shcedule[0] === '' || shcedule[1] === '') {
        this.query.startDate = ''
        this.query.endDate = ''
      } else {
        this.query.startDate = GetDateStr(shcedule[0], 1)
        this.query.endDate = GetDateStr(shcedule[1], 1)
      }
    },
    SearchOrders () {
      this.query.pageNumber = 1
      this.initOrderList()
    },
    changePage (currentPage) {
      this.query.pageNumber = currentPage
      this.initOrderList()
    },
    handleShowDetail (orderBean) {
      this.$store.commit('set_order_bean', orderBean)
      // 设置传递参数

      // 设置头部路由标签
      this.setLinkTagArray({ key: 'ownerOrderDetail', value: '订单详情' })
      this.setLinkRouterMaps(['ownerOrderDetail', () => {
        this.setShowLeftBlock(false)
        this.setShowMap(false)
        this.setShowRightBlock(false)
        this.setLeftComponent('')
        this.setRightComponent('')
        this.setBottomComponent('Detail')
      }])
      this.setActivedTagName('ownerOrderDetail')
    }
  }
}
</script>
